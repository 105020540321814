import React, { Component } from "react";
import "@tensorflow/tfjs";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "./App.css";

class Particle {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.size = Math.random() * 5 + 1;
    this.speedX = Math.random() * 3 - 1.5;
    this.speedY = Math.random() * 3 - 1.5;
    this.color = 'rgba(250, 0, 255, 0.5)';
  }

  update() {
    this.x += this.speedX;
    this.y += this.speedY;
    this.size *= 0.95; // パーティクルのサイズを徐々に小さくする
  }

  draw(ctx) {
    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.fill();
  }
}

class App extends Component {
  state = {
    model: null,
    stream: null,
    videoElement: null,
    canvasElement: null,
    videoOpacity: 0.5,
    particles: [] // パーティクルを管理する配列
  };

  async componentDidMount() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user"
        },
        audio: false
      });

      const model = await cocoSsd.load();

      await this.setState({
        videoElement: this.refs.video,
        canvasElement: this.refs.canvas,
        stream,
        model
      });

      this.state.videoElement.srcObject = this.state.stream;
      this.predictFrame();
    } catch (err) {
      console.log(err);
    }
  }

  predictFrame = async () => {
    const predictions = await this.state.model.detect(this.refs.video);
    this.drawPredictions(predictions);
    this.updateParticles();
    this.predictFrame();
  };

  drawPredictions = predictions => {
    const ctx = this.state.canvasElement.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    predictions.forEach(prediction => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];

      ctx.strokeStyle = "#fa00ff";
      ctx.lineWidth = 4;
      ctx.strokeRect(x, y, width, height);

      ctx.fillStyle = "#fa00ff";
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt(font, 10);
      ctx.fillRect(x, y, textWidth + 4, textHeight + 4);

      ctx.fillStyle = "#000000";
      ctx.fillText(prediction.class, x, y);

      // 四角形の中央からパーティクルを発生させる
      const centerX = x + width / 2;
      const centerY = y + height / 2;
      for (let i = 0; i < 10; i++) { // 一度に10個のパーティクルを生成
        this.state.particles.push(new Particle(centerX, centerY));
      }
    });
  };

  updateParticles = () => {
    const ctx = this.state.canvasElement.getContext("2d");
    this.state.particles.forEach((particle, index) => {
      particle.update();
      particle.draw(ctx);
      // パーティクルが小さくなりすぎたら配列から削除
      if (particle.size < 0.5) {
        this.state.particles.splice(index, 1);
      }
    });
  };

  handleTap = () => {
    this.setState(prevState => ({
      videoOpacity: prevState.videoOpacity === 1 ? 0 : 1
    }));
  };

  render() {
    return (
      <div id="videoContainer" onClick={this.handleTap}>
        <video
          className="position"
          style={{ opacity: this.state.videoOpacity }}
          autoPlay
          playsInline
          muted
          ref="video"
          width="600"
          height="500"
        />
        <canvas className="position" ref="canvas" width="600" height="500" />
      </div>
    );
  }
}

export default App;
